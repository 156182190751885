<template>
  <button
    class="button-new flex w-full items-center rounded-[8px] border border-collection font-medium uppercase text-collection sm:w-fit"
    :disabled="!!isDisabled"
    :class="[getClasses, { 'justify-center': isNotDesktop }]"
    @click="handleClick"
  >
    <SvgIcon
      v-if="icon && iconPosition === 'left'"
      :name="icon"
      class="size-4 min-w-[16px]"
      :class="{ 'mr-3': !iconOnly && text }"
    />

    <template v-if="text && !iconOnly">{{ text }}</template>

    <SvgIcon
      v-if="icon && iconPosition === 'right'"
      :name="icon"
      class="size-4 min-w-[16px]"
      :class="{
        'ml-3': !iconOnly && text,
        social: iconOnly && isSocial,
      }"
    />
  </button>
</template>

<script setup>
const props = defineProps({
  icon: {
    type: String,
    default: '',
  },

  isDisabled: {
    type: Boolean,
    default: false,
  },

  iconOnly: {
    type: Boolean,
    default: false,
  },

  // флаг, ведет ли ссылка за пределы сайта
  isExternal: {
    type: Boolean,
    default: false,
  },

  isSmall: {
    type: Boolean,
    default: false,
  },

  isSocial: {
    type: Boolean,
    default: false,
  },

  isLogOut: {
    type: Boolean,
    default: false,
  },

  text: {
    type: String,
    default: '',
  },

  widthMin: {
    type: Boolean,
    default: false,
  },

  href: {
    type: String,
    default: '',
  },

  iconPosition: {
    type: String,
    default: 'right',
    validate: (value) => ['right', 'left'].includes(value),
  },

  type: {
    type: String,
    default: 'default',
    validate: (value) =>
      [
        'default, inverse, inverse--dark-border, inverse--red-border, secondary, transparent',
      ].includes(value),
  },
});

const router = useRouter();
const { isNotDesktop } = useBreakpoint();

const getClasses = computed(() => {
  let res = '';

  if (props.isLogOut) return (res = 'button-new__logout_button');

  res +=
    props.isSocial && props.iconOnly && isNotDesktop.value
      ? 'p-1 w-[30px] h-[30px] '
      : props.isSocial && props.iconOnly
      ? 'p-3 w-[48px] h-[48px] '
      : props.isSmall
      ? 'py-4 px-6 text-[12px] leading-[12px] md:text-[14px] md:leading-[17px] '
      : 'py-5 px-8 text-[18px] ';

  // if (this.text && !this.iconOnly) res += 'w-fit '

  switch (props.type) {
    case 'default':
      res += 'button-new__default ';
      break;
    case 'inverse':
      res += 'button-new__inverse ';
      break;
    case 'inverse--dark-border':
      res += 'button-new__inverse--dark-border ';
      break;
    case 'inverse--red-border':
      res += 'button-new__inverse--red-border ';
      break;
    case 'secondary':
      res += 'button-new__secondary ';
      break;
    case 'secondary--disabled':
      res += 'button-new__secondary--inverse-disabled ';
      break;
    case 'transparent':
      res += 'button-new__transparent ';
      break;
    default:
      res += 'button-new__default ';
      break;
  }

  // if (this.iconOnly) res += 'rounded-50%'

  if (props.widthMin) res += 'w-min';

  return res;
});

const handleClick = () => {
  if (!props.href) return;
  if (props.isExternal) {
    window.open(props.href);
  } else {
    router.push(props.href);
  }
};
</script>

<style lang="scss">
.button-new {
  &__default {
    @apply text-ruby-red-100 md:hover:text-white-100 bg-white-100 md:hover:bg-ruby-red-100 max-md:active:text-white-100 max-md:active:bg-ruby-red-100;
    @apply transition-all ease-in-out duration-300;
    @apply border-[1.75px] border-ruby-red-100;
  }

  &__inverse,
  &__inverse--red-border {
    @apply transition-all ease-in-out duration-300;
    @apply border-[1.75px] border-white-100;
    @apply md:hover:text-ruby-red-100 md:hover:bg-white-100 max-md:active:text-ruby-red-100 max-md:active:bg-white-100;
  }
  &__inverse--dark-border {
    @apply transition-all ease-in-out duration-300 border;
    @apply bg-[#1f1f1f] text-white-100 border-[#1f1f1f];
    @apply md:hover:text-[#1f1f1f] md:hover:bg-white-100 md:hover:border-white-100;
  }
  &__inverse--red-border {
    @apply border-new-red-dark;
  }

  &__transparent {
    @apply border-white-100 text-white-100 bg-[#0000];
  }

  &__secondary {
    @apply transition-all ease-in-out duration-300 text-white-100;
    @apply border-[1.75px] border-ruby-red-100;
    @apply bg-ruby-red-100 md:hover:bg-white-100 md:hover:text-ruby-red-100;
  }
  &__secondary--inverse-disabled {
    @apply text-[#808080];
    @apply border-[1.75px] border-[#E1E1E1];
    @apply bg-[#E1E1E1];
  }

  &__logout_button {
    @apply border-0 bg-[#E1E1E1] text-[#808080] py-4 px-6 text-[14px] leading-[17px];
  }
}
.button-new:disabled {
  @apply pointer-events-none opacity-[0.32];
}
.button-new > svg.sprite-icons.social {
  @apply lg:h-5 lg:w-5;
}
</style>
